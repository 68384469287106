@font-face {
  font-family: 'icomoon';
  src: url("/common/fonts/icomoon.eot?axcsxa");
  src: url("/common/fonts/icomoon.eot?axcsxa#iefix") format("embedded-opentype"), url("/common/fonts/icomoon.ttf?axcsxa") format("truetype"), url("/common/fonts/icomoon.woff?axcsxa") format("woff"), url("/common/fonts/icomoon.svg?axcsxa#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon-pin:before {
  content: "\e900";
}

.icon-icon-next:before {
  content: "\e901";
}

@font-face {
  font-family: 'icomoon';
  src: url("/common/fonts/icomoon.eot?axcsxa");
  src: url("/common/fonts/icomoon.eot?axcsxa#iefix") format("embedded-opentype"), url("/common/fonts/icomoon.ttf?axcsxa") format("truetype"), url("/common/fonts/icomoon.woff?axcsxa") format("woff"), url("/common/fonts/icomoon.svg?axcsxa#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon-pin:before {
  content: "\e900";
}

.icon-icon-next:before {
  content: "\e901";
}

.gl-header_area {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 101;
  background: #FFF;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  transition: .5s;
}

@media screen and (max-width: 600px) {
  .gl-header_area {
    position: absolute;
    height: 56px;
  }
}

.gl-header_area--wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  padding: 18px 24px;
}

@media screen and (max-width: 600px) {
  .gl-header_area--wrap {
    padding: 18px 16px;
    height: 57px;
  }
}

.gl-header_area--logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .gl-header_area--logo {
    width: calc(100% - 64px);
  }
}

.gl-header_area--link {
  display: block;
  margin-left: 16px;
}

@media screen and (max-width: 600px) {
  .gl-header_area--link {
    margin-left: 8px;
  }
}

.gl-header_area--link:hover .gl-header_area--img {
  opacity: .8;
  transition: .15s;
}

.gl-header_area--link:first-child {
  margin-left: 0;
}

.gl-header_area--link-tokyo {
  width: 94px;
}

@media screen and (max-width: 600px) {
  .gl-header_area--link-tokyo {
    width: 56px;
  }
}

.gl-header_area--link-foundation {
  width: 150px;
}

@media screen and (max-width: 600px) {
  .gl-header_area--link-foundation {
    width: 74px;
  }
}

@media screen and (max-width: 600px) {
  .gl-header_area--link-logo {
    width: 132px;
  }
}

.gl-header_area.js-gl-header_area-is_top {
  position: absolute;
  box-shadow: none;
  background: none;
  opacity: 1;
  visibility: visible;
  transition: 0.5s;
}

.gl-header_area.js-gl-header_area-is_top .gl-header_area--wrap {
  width: 100%;
  padding: 40px 24px 20px;
}

@media screen and (max-width: 600px) {
  .gl-header_area.js-gl-header_area-is_top .gl-header_area--wrap {
    width: 100%;
    padding: 14px 16px;
  }
}

.gl-header_area.js-gl-header_area-is_top .gl-header_area--logo {
  width: 360px;
}

@media screen and (max-width: 600px) {
  .gl-header_area.js-gl-header_area-is_top .gl-header_area--logo {
    width: calc(100% - 64px);
    justify-content: flex-start;
    opacity: 1;
  }
}

.gl-header_area.js-gl-header_area-is_top .gl-header_area--link-tokyo {
  width: 125px;
}

@media screen and (max-width: 600px) {
  .gl-header_area.js-gl-header_area-is_top .gl-header_area--link-tokyo {
    width: 92px;
    margin: 0 16px 0 0;
  }
}

.gl-header_area.js-gl-header_area-is_top .gl-header_area--link-foundation {
  width: 214px;
}

@media screen and (max-width: 600px) {
  .gl-header_area.js-gl-header_area-is_top .gl-header_area--link-foundation {
    width: 150px;
  }
}

.gl-header_area.js-gl-header_area-is_top .gl-header_area--link-logo {
  display: none;
}

@media screen and (max-width: 600px) {
  .gl-header_area.js-gl-header_area-active {
    position: fixed;
    background: #fff;
  }
}

@media screen and (max-width: 600px) {
  .gl-header_area.js-gl-header_area-active .gl-header_area--logo {
    width: calc(100% - 64px);
    justify-content: flex-start;
  }
}

@media screen and (max-width: 600px) {
  .gl-header_area.js-gl-header_area-active .gl-header_area--link-tokyo {
    width: 92px;
    margin: 0 16px 0 0;
  }
}

@media screen and (max-width: 600px) {
  .gl-header_area.js-gl-header_area-active .gl-header_area--link-foundation {
    width: 120px;
  }
}

@media screen and (max-width: 600px) {
  .gl-header_area.js-gl-header_area-active .gl-header_area--link-logo {
    display: none;
  }
}

.gl-header_nav {
  width: 656px;
}

@media screen and (max-width: 600px) {
  .gl-header_nav {
    width: 100vw;
    height: 100vh;
    padding: 40px 24px;
    background: #fff;
    position: fixed;
    top: 56px;
    left: 0;
    overflow: scroll;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
  }
}

@media screen and (max-width: 600px) {
  .gl-header_nav.js-gl-header_nav-active {
    visibility: visible;
    opacity: 1;
  }
}

@media screen and (max-width: 600px) {
  .gl-header_nav.js-gl-header_nav-active .gl-header_nav--wrap {
    opacity: 1;
    visibility: visible;
  }
}

@media screen and (max-width: 600px) {
  .gl-header_nav.js-gl-header_nav-active .gl-header_nav--facebook {
    opacity: 1;
    visibility: visible;
  }
}

.gl-header_nav--wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media screen and (max-width: 600px) {
  .gl-header_nav--wrap {
    display: block;
    margin: 0 0 32px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s, visibility 1s;
  }
}

.gl-header_nav--link {
  padding: 3px 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.gl-header_nav--link:before {
  content: '';
  width: 1px;
  height: 20px;
  display: inline-block;
  background: #EBEBEB;
  position: absolute;
  left: 0;
}

.gl-header_nav--link:first-of-type {
  padding: 3px 24px;
}

.gl-header_nav--link:last-of-type:after {
  content: '';
  width: 1px;
  height: 20px;
  display: inline-block;
  background: #EBEBEB;
  position: absolute;
  right: 0;
}

@media screen and (max-width: 600px) {
  .gl-header_nav--link {
    display: block;
    padding: 20px 0 20px 72px;
    text-align: left;
    border-top: 1px dotted #0C3976;
  }
  .gl-header_nav--link:before {
    content: '';
    width: 8px;
    height: 8px;
    display: inline-block;
    background: none;
    border-top: 3px solid #0061C3;
    border-right: 3px solid #0061C3;
    transform: rotate(45deg);
    position: absolute;
    left: 24px;
  }
  .gl-header_nav--link:first-of-type {
    padding: 20px 0 20px 72px;
  }
  .gl-header_nav--link:last-of-type {
    border-bottom: 1px dotted #0C3976;
  }
  .gl-header_nav--link:last-of-type:after {
    content: none;
  }
}

.gl-header_nav--box {
  font-size: 14px;
  font-weight: bold;
  display: block;
}

.gl-header_nav--box:after {
  content: '';
  width: 0;
  height: 3px;
  background: #0C3976;
  display: inline-block;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translate(-50%, 0);
  transition: .15s;
}

.gl-header_nav--box:hover:after {
  width: 80%;
}

@media screen and (max-width: 600px) {
  .gl-header_nav--box {
    font-size: 16px;
  }
  .gl-header_nav--box:hover:after {
    width: 0;
  }
}

@media screen and (max-width: 600px) {
  .gl-header_nav--link_sp {
    display: block;
    margin: 0 auto 32px;
    width: 245px;
  }
}

.gl-header_nav--img {
  display: none;
}

@media screen and (max-width: 600px) {
  .gl-header_nav--img {
    display: block;
  }
}

.gl-header_nav--strong {
  display: none;
}

@media screen and (max-width: 600px) {
  .gl-header_nav--strong {
    font-size: 16px;
    display: block;
    text-align: center;
    margin: 0 0 40px;
    position: relative;
  }
  .gl-header_nav--strong:after {
    content: '';
    width: 40px;
    height: 5px;
    display: inline-block;
    background: linear-gradient(to left, #0061C3, #0C3976);
    position: absolute;
    bottom: -14px;
    left: calc(50% - 20px);
  }
}

.gl-header_nav--facebook {
  display: none;
}

@media screen and (max-width: 600px) {
  .gl-header_nav--facebook {
    font-weight: bold;
    color: #fff;
    display: block;
    width: calc(100% - 40px);
    margin: 0 auto;
    background: #1877F2;
    padding: 13px 10px 13px 40px;
    border-radius: 8px;
    position: relative;
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s, visibility 1s;
  }
  .gl-header_nav--facebook:before {
    content: '';
    width: 24px;
    height: 24px;
    display: inline-block;
    background: url("/common/images/gl-header_nav--facebook.svg") no-repeat;
    background-size: contain;
    position: absolute;
    left: 8px;
    top: calc(50% - 12px);
  }
}

.gl-header_menu {
  display: none;
}

@media screen and (max-width: 600px) {
  .gl-header_menu {
    display: block;
    background: #0C3976;
    width: 56px;
    height: 56px;
    position: fixed;
    top: 0;
    right: 0;
  }
  .gl-header_menu--bar {
    position: absolute;
    right: calc(50% - 10px);
    width: 20px;
    height: 3px;
    background-color: #fff;
    display: inline-block;
    transition: all .3s 0s ease;
  }
  .gl-header_menu--bar:nth-of-type(1) {
    top: 16px;
  }
  .gl-header_menu--bar:nth-of-type(2) {
    top: 23px;
  }
  .gl-header_menu--bar:nth-of-type(3) {
    top: 30px;
  }
  .gl-header_menu--words {
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    position: absolute;
    bottom: 8px;
    left: calc(50% - 15px);
  }
  .gl-header_menu--close {
    display: none;
    font-size: 10px;
    font-weight: bold;
    color: #0C3976;
    position: absolute;
    bottom: 8px;
    left: calc(50% - 15px);
  }
  .gl-header_menu.js-gl-header_menu-active {
    background: #F0F0F0;
  }
  .gl-header_menu.js-gl-header_menu-active .gl-header_menu--bar {
    background: #0C3976;
  }
  .gl-header_menu.js-gl-header_menu-active .gl-header_menu--bar:nth-of-type(1) {
    transform: rotate(45deg);
    top: 23px;
  }
  .gl-header_menu.js-gl-header_menu-active .gl-header_menu--bar:nth-of-type(2) {
    opacity: 0;
  }
  .gl-header_menu.js-gl-header_menu-active .gl-header_menu--bar:nth-of-type(3) {
    transform: rotate(-45deg);
    top: 23px;
  }
  .gl-header_menu.js-gl-header_menu-active .gl-header_menu--words {
    display: none;
  }
  .gl-header_menu.js-gl-header_menu-active .gl-header_menu--close {
    display: block;
  }
}

@font-face {
  font-family: 'icomoon';
  src: url("/common/fonts/icomoon.eot?axcsxa");
  src: url("/common/fonts/icomoon.eot?axcsxa#iefix") format("embedded-opentype"), url("/common/fonts/icomoon.ttf?axcsxa") format("truetype"), url("/common/fonts/icomoon.woff?axcsxa") format("woff"), url("/common/fonts/icomoon.svg?axcsxa#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon-pin:before {
  content: "\e900";
}

.icon-icon-next:before {
  content: "\e901";
}

.gl-footer_area--link {
  text-align: center;
  background: linear-gradient(to left, #0061C3, #0C3976);
  padding: 28px 0;
  display: block;
}

.gl-footer_area--link:hover .gl-footer_area--page_top {
  transform: translate(0, -8px);
}

.gl-footer_area--page_top {
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  display: block;
  position: relative;
  transition: .15s;
}

.gl-footer_area--page_top:before {
  content: '';
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url("/common/images/gl-footer_area--page_top.png") no-repeat;
  background-size: contain;
  position: absolute;
  left: calc(50% - 72px);
  top: calc(50% - 12px);
}

.gl-footer_area--wrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 324px;
}

@media screen and (max-width: 600px) {
  .gl-footer_area--wrap {
    display: block;
    height: auto;
  }
}

.gl-footer_area--map {
  width: 50%;
  overflow: hidden;
  position: relative;
}

@media screen and (max-width: 600px) {
  .gl-footer_area--map {
    width: 100%;
    height: 240px;
  }
}

.gl-footer_area--iframe {
  width: 100%;
  height: calc(100% + 360px);
  position: absolute;
  top: -170px;
  left: 0;
}

.gl-footer_foundation {
  width: 50%;
  padding: 40px 40px 40px calc(50% - 502px);
}

@media screen and (max-width: 600px) {
  .gl-footer_foundation {
    width: calc(100% - 40px);
    margin: 0 auto;
    padding: 40px 0 32px;
  }
}

.gl-footer_foundation--border {
  border-left: 5px solid #EB7612;
  padding: 4px 0 4px 16px;
  margin: 0 0 16px;
}

.gl-footer_foundation--ttl {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.4;
  margin: 0 0 16px;
}

@media screen and (max-width: 600px) {
  .gl-footer_foundation--ttl {
    font-size: 16px;
  }
}

.gl-footer_foundation--time {
  line-height: 1.3;
}

.gl-footer_foundation--link_text {
  margin: 0 0 16px;
  line-height: 1.3;
  display: flex;
}

.gl-footer_foundation--link {
  line-break: anywhere;
  transition: .15s;
  text-decoration: underline;
}

.gl-footer_foundation--link-url {
  width: 300px;
}

.gl-footer_foundation--link:hover {
  color: #0061C4;
}

.gl-footer_foundation--text {
  line-height: 1.8;
}

@media screen and (max-width: 600px) {
  .gl-footer_foundation--text {
    font-size: 12px;
  }
}

.gl-footer_foundation--text_red {
  color: #ff0000;
}

.gl-footer_office {
  background: #333333;
  padding: 32px 0 40px;
}

.gl-footer_office--box {
  width: 315px;
}

@media screen and (max-width: 600px) {
  .gl-footer_office--box {
    width: 100%;
    margin: 0 0 40px;
  }
}

.gl-footer_office--inner {
  width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media screen and (max-width: 600px) {
  .gl-footer_office--inner {
    width: calc(100% - 40px);
    display: block;
  }
}

.gl-footer_office--border {
  border-left: 5px solid #0061C3;
  padding: 4px 0 4px 16px;
  margin: 0 0 24px;
}

.gl-footer_office--ttl {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  line-height: 1.5;
  margin: 0 0 8px;
}

@media screen and (max-width: 600px) {
  .gl-footer_office--ttl {
    font-size: 16px;
    line-height: 2;
  }
}

.gl-footer_office--time {
  color: #fff;
}

.gl-footer_office--address {
  color: #fff;
  line-height: 2;
  margin: 0 0 24px;
}

@media screen and (max-width: 600px) {
  .gl-footer_office--address {
    font-size: 12px;
    margin: 0 0 16px;
  }
}

.gl-footer_office--text {
  color: #fff;
  line-height: 1.5;
}

@media screen and (max-width: 600px) {
  .gl-footer_office--text {
    font-size: 12px;
  }
}

.gl-footer_office--link {
  text-decoration: underline;
  transition: .15s;
}

.gl-footer_office--link:hover {
  opacity: .8;
}

.gl-footer_nav {
  padding: 0 20px 0 0;
  border-right: 1px solid #7C7C7C;
  position: relative;
}

@media screen and (max-width: 600px) {
  .gl-footer_nav {
    padding: 0;
    border-right: none;
    border-bottom: 1px solid #7C7C7C;
    margin: 0 0 24px;
  }
}

.gl-footer_nav--nav {
  width: 175px;
}

@media screen and (max-width: 600px) {
  .gl-footer_nav--nav {
    width: 100%;
    padding: 0 20px;
    margin: 0 0 40px;
  }
}

.gl-footer_nav--link {
  font-weight: bold;
  color: #fff;
  line-height: 1.5;
  display: block;
  margin: 16px 0 0;
  transition: .15s;
  position: relative;
}

.gl-footer_nav--link:before {
  content: '';
  width: 6px;
  height: 6px;
  display: inline-block;
  border-top: 3px solid #0061C3;
  border-right: 3px solid #0061C3;
  transform: rotate(45deg);
  position: absolute;
  left: -21px;
  top: calc(50% - 6px);
}

.gl-footer_nav--link:hover {
  color: #0061C3;
}

.gl-footer_nav--link:first-of-type {
  margin: 0;
}

.gl-footer_nav--facebook {
  font-weight: bold;
  color: #fff;
  display: block;
  background: #1877F2;
  border-radius: 8px;
  width: 287px;
  padding: 13px 13px 13px 40px;
  position: absolute;
  left: -20px;
  bottom: -94px;
  transition: .15s;
}

.gl-footer_nav--facebook:before {
  content: '';
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url("/common/images/gl-header_nav--facebook.svg") no-repeat;
  background-size: contain;
  position: absolute;
  left: 8px;
  top: calc(50% - 12px);
}

.gl-footer_nav--facebook:hover {
  background: #0061C3;
}

@media screen and (max-width: 600px) {
  .gl-footer_nav--facebook {
    width: calc(100% - 48px);
    margin: 0 auto 40px;
    background: #1877F2;
    padding: 13px 10px 13px 40px;
    border-radius: 8px;
    position: relative;
    left: auto;
    bottom: auto;
  }
  .gl-footer_nav--facebook:before {
    left: 8px;
    top: calc(50% - 12px);
  }
}

.gl-footer_others {
  width: 394px;
}

@media screen and (max-width: 600px) {
  .gl-footer_others {
    width: 100%;
    padding: 0 0 0 20px;
  }
}

.gl-footer_others--wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media screen and (max-width: 600px) {
  .gl-footer_others--box {
    width: 50%;
  }
}

.gl-footer_others--link {
  font-size: 12px;
  color: #fff;
  line-height: 1.7;
  display: block;
  margin: 16px 0 0;
  transition: .15s;
  position: relative;
}

.gl-footer_others--link:before {
  content: '';
  width: 6px;
  height: 6px;
  display: inline-block;
  border-top: 3px solid #7C7C7C;
  border-right: 3px solid #7C7C7C;
  transform: rotate(45deg);
  position: absolute;
  left: -21px;
  top: calc(50% - 4px);
}

.gl-footer_others--link:hover {
  color: #0061C3;
}

.gl-footer_others--link:first-of-type {
  margin: 0;
}

.gl-footer_info {
  background: #F0F0F0;
  padding: 16px 0 32px;
}

.gl-footer_info--wrap {
  width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media screen and (max-width: 600px) {
  .gl-footer_info--wrap {
    width: 100%;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .gl-footer_info--link_wrap {
    margin-bottom: 24px;
  }
}

.gl-footer_info--link {
  color: #333333;
  line-height: 1.5;
  transition: .15s;
  margin: 0 20px 0 20px;
  position: relative;
}

.gl-footer_info--link:before {
  content: '';
  width: 6px;
  height: 6px;
  display: inline-block;
  border-top: 3px solid #0061C3;
  border-right: 3px solid #0061C3;
  transform: rotate(45deg);
  position: absolute;
  left: -21px;
  top: calc(50% - 4px);
}

.gl-footer_info--link:hover {
  color: #0061C3;
}

@media screen and (max-width: 600px) {
  .gl-footer_info--link {
    margin: 0 0 8px;
    text-align: center;
    display: block;
  }
  .gl-footer_info--link:before {
    position: relative;
    top: auto;
    left: auto;
    margin-right: 4px;
  }
}

.gl-footer_info--link:last-child {
  margin-right: 0;
}

@media screen and (max-width: 600px) {
  .gl-footer_info--link:last-child {
    margin-bottom: 0;
  }
}

.gl-footer_info--description {
  text-align: right;
}

@media screen and (max-width: 600px) {
  .gl-footer_info--description {
    text-align: center;
  }
}

.gl-footer_info--text {
  font-size: 12px;
  line-height: 1.5;
  margin: 0 0 8px;
}

@media screen and (max-width: 600px) {
  .gl-footer_info--text {
    margin: 0 0 16px;
  }
}

.gl-footer_info--copyright {
  font-size: 12px;
}

body {
  min-width: 1280px;
}

@media screen and (max-width: 600px) {
  body {
    min-width: inherit;
  }
}

.wrap {
  padding-top: 68px;
  overflow: hidden;
}

.wrap-flow, .wrap-seminar {
  background-color: #EDF3F9;
}

.wrap-top {
  padding: 96px 0 0;
}

@media screen and (max-width: 600px) {
  .wrap-top {
    padding: 56px 0 0;
  }
}

.wrap-gomen_company {
  padding-top: 70px;
}

@media screen and (max-width: 600px) {
  .wrap {
    padding-top: 56px;
  }
}

.cm-visual {
  position: relative;
  width: 100%;
  height: 260px;
}

.cm-visual--ttl {
  position: absolute;
  bottom: 143px;
  font-weight: bold;
  font-size: 30px;
  color: #003BA6;
  width: 960px;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: block;
}

@media screen and (max-width: 600px) {
  .cm-visual--ttl {
    font-size: 24px;
    width: 100%;
    bottom: 20px;
    left: 23px;
  }
}

.cm-visual--free_img {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 152px;
}

@media screen and (max-width: 600px) {
  .cm-visual--free_img {
    width: 120px;
    right: 15px;
    top: auto;
    bottom: 15px;
  }
}

@media screen and (max-width: 600px) {
  .cm-visual {
    height: 156px;
    padding: 0;
    display: block;
  }
}

.cm-visual-flow {
  background: url("/flow/images/img-main-visual.jpg") no-repeat center center;
  background-size: cover;
}

@media screen and (max-width: 600px) {
  .cm-visual-flow {
    background: url("/flow/images/sp-img-main-visual.jpg") no-repeat center center;
    background-size: cover;
  }
}

.cm-visual-seminar {
  background: url("/seminar/images/img-main-visual.jpg") no-repeat center center;
  background-size: cover;
}

@media screen and (max-width: 600px) {
  .cm-visual-seminar {
    background: url("/seminar/images/sp-img-main-visual.jpg") no-repeat center center;
    background-size: cover;
  }
}

.cm-visual-seminar_report {
  background: url("/seminar/report/staff/images/img-main-visual.jpg") no-repeat center center;
  background-size: cover;
}

@media screen and (max-width: 600px) {
  .cm-visual-seminar_report {
    background: url("/seminar/report/staff/images/sp-img-main-visual.jpg") no-repeat center center;
    background-size: cover;
  }
}

.cm-visual-seminar_staff {
  background: url("/seminar/images/img-main-visual-staff.jpg") no-repeat center center;
  background-size: cover;
}

@media screen and (max-width: 600px) {
  .cm-visual-seminar_staff {
    background: url("/seminar/images/sp-img-main-visual-staff.jpg") no-repeat center center;
    background-size: cover;
  }
}

.cm-visual-seminar_katsuyou {
  background: url("/seminar/images/img-main-visual-katsuyou.jpg") no-repeat center center;
  background-size: cover;
}

@media screen and (max-width: 600px) {
  .cm-visual-seminar_katsuyou {
    background: url("/seminar/images/sp-img-main-visual-katsuyou.jpg") no-repeat center center;
    background-size: cover;
  }
}

.cm-visual-lead {
  position: relative;
  background: rgba(0, 30, 120, 0.9);
  right: calc(50% - 240px);
  margin-top: -60px;
}

@media screen and (max-width: 600px) {
  .cm-visual-lead {
    margin-top: 0;
  }
}

@media screen and (max-width: 600px) {
  .cm-visual-lead-seminar {
    padding-bottom: 20px;
  }
  .cm-visual-lead-seminar:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    font-size: 0;
    content: '.';
  }
}

.cm-visual-lead-consulting {
  right: calc(50% - 290px);
}

.cm-visual-lead--txt {
  font-size: 17px;
  font-weight: bold;
  color: #fff;
  line-height: 2;
  padding-top: 25px;
  padding-left: calc(100% - 720px);
  padding-bottom: 25px;
}

.cm-visual-lead--txt span {
  font-size: 14px;
  display: block;
  margin-top: -5px;
}

@media screen and (max-width: 600px) {
  .cm-visual-lead--txt {
    top: 0;
    padding: 15px;
    font-size: 14px;
  }
}

.cm-visual-lead--txt-blue {
  color: #50B6E1;
  font-size: 16px;
  padding-bottom: 15px;
}

.cm-visual-lead--txt-bold {
  padding-top: 0;
  font-size: 24px;
  line-height: 1.5;
}

.cm-visual-lead--txt-seminar {
  font-size: 24px;
  line-height: 1.5;
}

@media screen and (max-width: 600px) {
  .cm-visual-lead--txt-seminar {
    font-size: 20px;
    padding-top: 24px;
  }
}

.cm-visual-lead--txt-consulting {
  padding-left: calc(100% - 770px);
}

@media screen and (max-width: 600px) {
  .cm-visual-lead--txt-consulting {
    padding: 15px 15px 56px;
  }
}

.cm-visual-lead--txt_type {
  font-size: 16px;
  color: #50B6E1;
  margin-bottom: 12px;
}

.cm-visual-lead--free {
  font-size: 24px;
  font-weight: bold;
  color: #EF5923;
  padding: 4px 10px;
  background-color: #fff;
  border: 2px solid #EF5923;
  border-radius: 20px;
  position: absolute;
  top: 90px;
  right: -196px;
}

@media screen and (max-width: 600px) {
  .cm-visual-lead--free {
    position: relative;
    top: initial;
    right: initial;
    font-size: 16px;
    display: inline-block;
    float: right;
    margin-right: 24px;
  }
}

.cm-visual-lead--link {
  text-decoration: underline;
}

.cm-visual-lead--link:hover {
  text-decoration: none;
}

.cm-visual-lead--facebook_btn {
  position: absolute;
  right: -212px;
  top: 0;
  transition: 0.15s;
}

.cm-visual-lead--facebook_btn:hover {
  opacity: 0.8;
}

@media screen and (max-width: 600px) {
  .cm-visual-lead--facebook_btn {
    position: static;
    margin: 0 auto;
    display: block;
    width: 188px;
    height: 100px;
  }
}

.cm-visual-lead-report {
  right: calc(50% - 280px);
}

@media screen and (max-width: 600px) {
  .cm-visual-lead-report {
    padding-bottom: 15px;
  }
}

.cm-visual-lead-report .cm-visual-lead--txt {
  padding-left: calc(100% - 762px);
}

@media screen and (max-width: 600px) {
  .cm-visual-lead-report .cm-visual-lead--txt {
    padding-left: 15px;
  }
}

@media screen and (max-width: 600px) {
  .cm-visual-lead-report .cm-visual-lead--txt-small {
    font-size: 18px;
  }
}

@media screen and (max-width: 600px) {
  .cm-visual-lead {
    right: initial;
  }
}

.page_top_btn {
  display: block;
  opacity: 0;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  position: fixed;
  bottom: 10px;
  right: 20px;
  z-index: 100;
  transition: .2s;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.4);
  background: rgba(0, 113, 187, 0.9);
}

.page_top_btn:before {
  content: '';
  display: block;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(-45deg);
  width: 12px;
  height: 12px;
  position: relative;
  top: 16px;
  left: 13px;
  transition: .2s;
}

.page_top_btn:hover {
  transition: .2s;
}

.page_top_btn:hover:before {
  top: 13px;
}

@media screen and (max-width: 600px) {
  .page_top_btn {
    right: 10px;
  }
}

.page-sub-ttl {
  position: relative;
  margin-bottom: 55px;
  font-weight: bold;
  font-size: 30px;
  display: block;
  text-align: center;
  color: #0061C4;
  line-height: 1.3;
  padding-top: 55px;
}

.page-sub-ttl:before {
  content: "";
  position: absolute;
  bottom: -20px;
  width: 30px;
  height: 2px;
  background: #0061C4;
  left: 0;
  right: 0;
  margin: 0 auto;
}

@media screen and (max-width: 600px) {
  .page-sub-ttl:before {
    bottom: -10px;
  }
}

@media screen and (max-width: 600px) {
  .page-sub-ttl {
    font-size: 22px;
    margin-bottom: 35px;
    padding-top: 35px;
  }
}
