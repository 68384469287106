@charset "UTF-8";

$color-blue: #0061C4;

// font-weight
$fw-bold: 700;

// #clearfix
@mixin clearfix {
  &:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    font-size: 0;
    content: '.';
  }
}

@font-face {
  font-family: 'icomoon';
  src:  url('/common/fonts/icomoon.eot?axcsxa');
  src:  url('/common/fonts/icomoon.eot?axcsxa#iefix') format('embedded-opentype'),
  url('/common/fonts/icomoon.ttf?axcsxa') format('truetype'),
  url('/common/fonts/icomoon.woff?axcsxa') format('woff'),
  url('/common/fonts/icomoon.svg?axcsxa#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@mixin icon{
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$icon-arrow : "\e901";

// for smartphone media query
@mixin sp {
  @media screen and(max-width:600px) {
    @content;
  }
}
@mixin sp320 {
  @media screen and(max-width:320px) {
    @content;
  }
}
@mixin dropshadow {
  box-shadow:0px 0px 10px rgba(0,0,0,0.3);
}
@mixin arrow {
  position: relative;
  &:after{
    @include icon;
    content: $icon-arrow;
    position: absolute;
    right: 20px;
    top: calc(50% - 0.5em);
  }
}
@mixin subTitle {
  position: relative;
  margin-bottom: 55px;
  font-weight: bold;
  font-size: 30px;
  display: block;
  text-align: center;
  color: $color-blue;
  line-height: 1.3;
  padding-top: 55px;
  &:before{
    content: "";
    position: absolute;
    bottom: -20px;
    width: 30px;
    height: 2px;
    background: $color-blue;
    left: 0;
    right: 0;
    margin: 0 auto;
    @include sp {
      bottom: -10px;
    }
  }
  @include sp {
    font-size: 22px;
    margin-bottom: 35px;
    padding-top: 35px;
  }
}

@mixin box-hover($bgcolor) {
  border:2px solid $bgcolor;
  transition:.15s;
  &:hover{
    color: $bgcolor;
    background:#fff;
  }
}



// hoiku/
$color-hoiku_gr: #64bfaa;
$color-hoiku_bl: #4fb7e2;
$color-hoiku_pk: #ff9bb9;

// saiyouryoku/
$color-saiyo_bl: #0071bb;
$color-saiyo_lbl: #4fb6e1;
$color-saiyo_gr: #6ac853;

// consulting
$color-consulting_gr: #3EAB76;
$color-consulting_lgr: #D5EAD8;

// #フォントサイズをremに変換
@mixin fz($size: 24, $base: 16) {
  font-size: $size + px;
  font-size: ($size / $base) * 1rem;
}

// IE transform
@mixin rotate($val) {
  -ms-transform: rotate($val + deg);
}

// background hover
@mixin hover_txtlink($color) {
  transition: .2s;
  &:hover {
    color: lighten($color, 20%);
    text-decoration: underline;
  }
}

// button hover
@mixin hoverbtn($color) {
  border: 2px solid $color;
  transition: color,border,background-color, .2s;
  &:hover {
    color: $color;
    background-color: #fff;
    border-color: $color;
  }
}
// background hover
@mixin hoverbg($color) {
  transition: .2s;
  &:hover {
    box-shadow: 0 0 3px 2px rgba(0,0,0,0.15);
    background-color: lighten($color, 3%)
  }
}

.icon-icon-pin:before {
  content: "\e900";
}
.icon-icon-next:before {
  content: "\e901";
}
