@charset "UTF-8";
@import '../mixin';

.gl-footer_area {
  &--link {
    text-align: center;
    background: linear-gradient(to left, #0061C3, #0C3976);
    padding: 28px 0;
    display: block;
    &:hover {
      .gl-footer_area--page_top {
        transform: translate(0,-8px);
      }
    }
  }
  &--page_top {
    font-size: 16px;
    color: #fff;
    font-weight: bold;
    display: block;
    position: relative;
    transition: .15s;
    &:before {
      content: '';
      width: 24px;
      height: 24px;
      display: inline-block;
      background: url("/common/images/gl-footer_area--page_top.png") no-repeat;
      background-size: contain;
      position: absolute;
      left: calc(50% - 72px);
      top: calc(50% - 12px);
    }
  }
  &--wrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 324px;
    @include sp {
      display: block;
      height: auto;
    }
  }
  &--map {
    width: 50%;
    overflow: hidden;
    position: relative;
    @include sp {
      width: 100%;
      height: 240px;
    }
  }
  &--iframe {
    width: 100%;
    height: calc(100% + 360px);
    position: absolute;
    top: -170px;
    left: 0;
  }
}

.gl-footer_foundation {
  width: 50%;
  padding: 40px 40px 40px calc(50% - 502px);
  @include sp {
    width: calc(100% - 40px);
    margin: 0 auto;
    padding: 40px 0 32px;
  }
  &--border {
    border-left: 5px solid #EB7612;
    padding: 4px 0 4px 16px;
    margin: 0 0 16px;
  }
  &--ttl {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;
    margin: 0 0 16px;
    @include sp {
      font-size: 16px;
    }
  }
  &--time {
    line-height: 1.3;
  }
  &--link_text {
    margin: 0 0 16px;
    line-height: 1.3;
    display: flex;
  }
  &--link_text_heading {}
  &--link {
    line-break: anywhere;
    transition: .15s;
    text-decoration: underline;
    &-url {
      width: 300px;
    }
    &:hover {
      color: $color-blue;
    }
  }
  &--text {
    line-height: 1.8;
    @include sp {
      font-size: 12px;
    }
  }
  &--text_red {
    color: #ff0000;
  }
}

.gl-footer_office {
  background: #333333;
  padding: 32px 0 40px;
  &--box {
    width: 315px;
    @include sp {
      width: 100%;
      margin: 0 0 40px;
    }
  }
  &--inner {
    width: 1000px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include sp {
      width: calc(100% - 40px);
      display: block;
    }
  }
  &--border {
    border-left: 5px solid #0061C3;
    padding: 4px 0 4px 16px;
    margin: 0 0 24px;
  }
  &--ttl {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    line-height: 1.5;
    margin: 0 0 8px;
    @include sp {
      font-size: 16px;
      line-height: 2;
    }
  }
  &--time {
    color: #fff;
  }
  &--address {
    color: #fff;
    line-height: 2;
    margin: 0 0 24px;
    @include sp {
      font-size: 12px;
      margin: 0 0 16px;
    }
  }
  &--text {
    color: #fff;
    line-height: 1.5;
    @include sp {
      font-size: 12px;
    }
  }
  &--link {
    text-decoration: underline;
    transition: .15s;
    &:hover {
      opacity: .8;
    }
  }
}

.gl-footer_nav {
  padding: 0 20px 0 0;
  border-right: 1px solid #7C7C7C;
  position: relative;
  @include sp {
    padding: 0;
    border-right: none;
    border-bottom: 1px solid #7C7C7C;
    margin: 0 0 24px;
  }
  &--nav {
    width: 175px;
    @include sp {
      width: 100%;
      padding: 0 20px;
      margin: 0 0 40px;
    }
  }
  &--link {
    font-weight: bold;
    color: #fff;
    line-height: 1.5;
    display: block;
    margin: 16px 0 0;
    transition: .15s;
    position: relative;
    &:before {
      content: '';
      width: 6px;
      height: 6px;
      display: inline-block;
      border-top: 3px solid #0061C3;
      border-right: 3px solid #0061C3;
      transform: rotate(45deg);
      position: absolute;
      left: -21px;
      top: calc(50% - 6px);
    }
    &:hover {
      color: #0061C3;
    }
    &:first-of-type {
      margin: 0;
    }
  }
  &--facebook {
    font-weight: bold;
    color: #fff;
    display: block;
    background: #1877F2;
    border-radius: 8px;
    width: 287px;
    padding: 13px 13px 13px 40px;
    position: absolute;
    left: -20px;
    bottom: -94px;
    transition: .15s;
    &:before {
      content: '';
      width: 24px;
      height: 24px;
      display: inline-block;
      background: url("/common/images/gl-header_nav--facebook.svg") no-repeat;
      background-size: contain;
      position: absolute;
      left: 8px;
      top: calc(50% - 12px);
    }
    &:hover {
      background: #0061C3;
    }
    @include sp {
      width: calc(100% - 48px);
      margin: 0 auto 40px;
      background: #1877F2;
      padding: 13px 10px 13px 40px;
      border-radius: 8px;
      position: relative;
      left: auto;
      bottom: auto;
      &:before {
        left: 8px;
        top: calc(50% - 12px);
      }
    }
  }
}

.gl-footer_others {
  width: 394px;
  @include sp {
    width: 100%;
    padding: 0 0 0 20px;
  }
  &--wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &--box {
    @include sp {
      width: 50%;
    }
  }
  &--link {
    font-size: 12px;
    color: #fff;
    line-height: 1.7;
    display: block;
    margin: 16px 0 0;
    transition: .15s;
    position: relative;
    &:before {
      content: '';
      width: 6px;
      height: 6px;
      display: inline-block;
      border-top: 3px solid #7C7C7C;
      border-right: 3px solid #7C7C7C;
      transform: rotate(45deg);
      position: absolute;
      left: -21px;
      top: calc(50% - 4px);
    }
    &:hover {
      color: #0061C3;
    }
    &:first-of-type {
      margin: 0;
    }
  }
}

.gl-footer_info {
  background: #F0F0F0;
  padding: 16px 0 32px;
  &--wrap {
    width: 1000px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include sp {
      width: 100%;
      display: block;
    }
  }
  &--link_wrap {
    @include sp {
      margin-bottom: 24px;
    }
  }
  &--link {
    color: #333333;
    line-height: 1.5;
    transition: .15s;
    margin: 0 20px 0 20px;
    position: relative;
    &:before {
      content: '';
      width: 6px;
      height: 6px;
      display: inline-block;
      border-top: 3px solid #0061C3;
      border-right: 3px solid #0061C3;
      transform: rotate(45deg);
      position: absolute;
      left: -21px;
      top: calc(50% - 4px);
    }
    &:hover {
      color: #0061C3;
    }
    @include sp {
      margin: 0 0 8px;
      text-align: center;
      display: block;
      &:before {
        position: relative;
        top: auto;
        left: auto;
        margin-right: 4px;
      }
    }
    &:last-child {
      margin-right: 0;
      @include sp {
        margin-bottom: 0;
      }
    }
  }
  &--description {
    text-align: right;
    @include sp {
      text-align: center;
    }
  }
  &--text {
    font-size: 12px;
    line-height: 1.5;
    margin: 0 0 8px;
    @include sp {
      margin: 0 0 16px;
    }
  }
  &--copyright {
    font-size: 12px;
  }
}
