@charset "UTF-8";

@import "mixin";

@import "common/gl-header";
@import "common/gl-footer";

body {
	min-width: 1280px;
	@include sp {
		min-width: inherit;
	}
}
.wrap {
  padding-top: 68px;
  overflow: hidden;
  &-flow,&-seminar {
    background-color: #EDF3F9;
  }
  &-top {
    padding: 96px 0 0;
    @include sp {
      padding: 56px 0 0;
    }
  }
  &-gomen_company {
    padding-top: 70px;
  }
  @include sp {
    padding-top: 56px;
  }
}

.cm-visual {
  position: relative;
  width: 100%;
  height: 260px;
  &--ttl {
    position: absolute;
    bottom: 143px;
    font-weight: bold;
    font-size: 30px;
    color: #003BA6;
    width: 960px;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: block;
    @include sp {
      font-size: 24px;
      width: 100% ;
      bottom: 20px;
      left: 23px;
    }
  }
  &--free_img{
    position: absolute;
    right: 32px;
    top: 32px;
    width: 152px;
    @include sp {
      width: 120px;
      right: 15px;
      top: auto;
      bottom: 15px;
    }
  }
  @include sp {
    height: 156px;
    padding: 0;
    display: block;
  }
  &-flow {
    background: url("/flow/images/img-main-visual.jpg") no-repeat center center;
    background-size:cover;
    @include sp {
      background: url("/flow/images/sp-img-main-visual.jpg") no-repeat center center;
      background-size:cover;
    }
  }
  &-seminar {
    background: url("/seminar/images/img-main-visual.jpg") no-repeat center center;
    background-size:cover;
    @include sp {
      background: url("/seminar/images/sp-img-main-visual.jpg") no-repeat center center;
      background-size:cover;
    }
  }
  &-seminar_report {
    background: url("/seminar/report/staff/images/img-main-visual.jpg") no-repeat center center;
    background-size: cover;
    @include sp {
      background: url("/seminar/report/staff/images/sp-img-main-visual.jpg") no-repeat center center;
      background-size: cover;
    }
  }
  &-seminar_staff {
    background: url("/seminar/images/img-main-visual-staff.jpg") no-repeat center center;
    background-size: cover;
    @include sp {
      background: url("/seminar/images/sp-img-main-visual-staff.jpg") no-repeat center center;
      background-size: cover;
    }
  }
  &-seminar_katsuyou {
    background: url("/seminar/images/img-main-visual-katsuyou.jpg") no-repeat center center;
    background-size: cover;
    @include sp {
      background: url("/seminar/images/sp-img-main-visual-katsuyou.jpg") no-repeat center center;
      background-size: cover;
    }
  }

}

.cm-visual-lead {
  position: relative;
  background: rgba(0,30,120,0.9);
  right: calc(50% - 240px);
  margin-top: -60px;
  @include sp {
    margin-top: 0;
  }
  &-seminar {
    @include sp {
      padding-bottom: 20px;
      @include clearfix;
    }
  }
  &-consulting {
    right: calc(50% - 290px);
  }
  &--txt {
    font-size: 17px;
    font-weight: bold;
    color: #fff;
    line-height: 2;
    padding-top: 25px;
    padding-left: calc(100% - 720px);
    padding-bottom: 25px;
    span{
      font-size: 14px;
      display: block;
      margin-top: -5px;
    }
    @include sp {
      top: 0;
      padding: 15px;
      font-size: 14px;
    }
    &-blue {
      color: #50B6E1;
      font-size: 16px;
      padding-bottom: 15px;
    }
    &-bold {
      padding-top: 0;
      font-size: 24px;
      line-height: 1.5;
    }
    &-seminar {
      font-size: 24px;
      line-height: 1.5;
      @include sp {
        font-size: 20px;
        padding-top: 24px;
      }
    }
    &-consulting {
      padding-left: calc(100% - 770px);
      @include sp {
        padding: 15px 15px 56px;
      }
    }
  }
  &--txt_type {
    font-size: 16px;
    color: #50B6E1;
    margin-bottom: 12px;
  }
  &--free {
    font-size: 24px;
    font-weight: bold;
    color: #EF5923;
    padding: 4px 10px;
    background-color: #fff;
    border: 2px solid #EF5923;
    border-radius: 20px;
    position: absolute;
    top: 90px;
    right: -196px;
    @include sp {
      position: relative;
      top: initial;
      right: initial;
      font-size: 16px;
      display: inline-block;
      float: right;
      margin-right: 24px;
    }
  }
  &--link{
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  &--facebook_btn {
    position: absolute;
    right: -212px;
    top: 0;
    transition: 0.15s;
    &:hover {
      opacity: 0.8;
    }
    @include sp {
      position: static;
      margin: 0 auto;
      display: block;
      width: 188px;
      height: 100px;
    }
  }
  &-report {
    right: calc(50% - 280px);
    @include sp {
      padding-bottom: 15px;
    }
    .cm-visual-lead--txt{
      padding-left: calc(100% - 762px);
      @include sp {
        padding-left: 15px;
      }
      &-small {
        @include sp {
          font-size: 18px;
        }
      }
    }
  }
  @include sp {
    right: initial;
  }
}


.page_top_btn {
  display: block;
  opacity: 0;
  width: 40px;
  height: 40px;
  border-radius:  20px;
  position: fixed;
  bottom: 10px;
  right: 20px;
  z-index: 100;
  transition: .2s;
  box-shadow: 0 0 3px 0 rgba(0,0,0,0.4);
  background: rgba(0,113,187,0.9);
  &:before {
    content: '';
    display: block;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(-45deg);
    width: 12px;
    height: 12px;
    position: relative;
    top: 16px;
    left: 13px;
    transition: .2s;
  }
  &:hover {;
    transition: .2s;
    &:before {
      top: 13px;
    }
  }
  @include sp {
    right: 10px;
  }
}

.page-sub-ttl {
  @include subTitle;
}
