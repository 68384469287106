@charset "UTF-8";
@import '../mixin';

.gl-header_area {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 101;
  background: #FFF;
  box-shadow: 0 3px 6px rgba(0,0,0,.16);
  transition: .5s;
  @include sp {
    position: absolute;
    height: 56px;
  }
  &--wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    padding: 18px 24px;
    @include sp {
      padding: 18px 16px;
      height: 57px;
    }
  }
  &--logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //width: 500px;
    @include sp {
      width: calc(100% - 64px);
    }
  }
  &--link {
    display: block;
    margin-left: 16px;
    @include sp {
      margin-left: 8px;
    }
    &:hover {
      .gl-header_area--img {
        opacity: .8;
        transition: .15s;
      }
    }
    &:first-child {
      margin-left: 0;
    }
    &-tokyo {
      width: 94px;
      @include sp {
        width: 56px;
      }
    }
    &-foundation {
      width: 150px;
      @include sp {
        width: 74px;
      }
    }
    &-logo {
      //width: 213px;
      @include sp {
        width: 132px;
      }
    }
  }
  &.js-gl-header_area-is_top {
    position: absolute;
    box-shadow: none;
    background: none;
    opacity: 1;
    visibility: visible;
    transition: 0.5s;
    .gl-header_area {
      &--wrap {
        width: 100%;
        padding: 40px 24px 20px;
        @include sp {
          width: 100%;
          padding: 14px 16px;
        }
      }
      &--logo {
        width: 360px;
        @include sp {
          width: calc(100% - 64px);
          justify-content: flex-start;
          opacity: 1;
        }
      }
      &--link {
        &-tokyo {
          width: 125px;
          @include sp {
            width: 92px;
            margin: 0 16px 0 0;
          }
        }
        &-foundation {
          width: 214px;
          @include sp {
            width: 150px;
          }
        }
        &-logo {
          display: none;
        }
      }
    }
  }
  &.js-gl-header_area-active {
    @include sp {
      position: fixed;
      background: #fff;
    }
    .gl-header_area {
      &--logo {
        @include sp {
          width: calc(100% - 64px);
          justify-content: flex-start;
        }
      }
      &--link {
        &-tokyo {
          @include sp {
            width: 92px;
            margin: 0 16px 0 0;
          }
        }
        &-foundation {
          @include sp {
            width: 120px;
          }
        }
        &-logo {
          @include sp {
            display: none;
          }
        }
      }
    }
  }
}

.gl-header_nav {
  width: 656px;
  @include sp {
    width: 100vw;
    height: 100vh;
    padding: 40px 24px;
    background: #fff;
    position: fixed;
    top: 56px;
    left: 0;
    overflow: scroll;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
  }
  &.js-gl-header_nav-active {
    @include sp {
      visibility: visible;
      opacity: 1;
    }
    .gl-header_nav {
      &--wrap {
        @include sp {
          opacity: 1;
          visibility: visible;
        }
      }
      &--facebook {
        @include sp {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  &--wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include sp {
      display: block;
      margin: 0 0 32px;
      opacity: 0;
      visibility: hidden;
      transition: opacity 1s, visibility 1s;
    }
  }
  &--link {
    padding: 3px 16px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &:before {
      content: '';
      width: 1px;
      height: 20px;
      display: inline-block;
      background: #EBEBEB;
      position: absolute;
      left: 0;
    }
    &:first-of-type {
      padding: 3px 24px;
    }
    &:last-of-type {
      &:after {
        content: '';
        width: 1px;
        height: 20px;
        display: inline-block;
        background: #EBEBEB;
        position: absolute;
        right: 0;
      }
    }
    @include sp {
      display: block;
      padding: 20px 0 20px 72px;
      text-align: left;
      border-top: 1px dotted #0C3976;
      &:before {
        content: '';
        width: 8px;
        height: 8px;
        display: inline-block;
        background: none;
        border-top: 3px solid #0061C3;
        border-right: 3px solid #0061C3;
        transform: rotate(45deg);
        position: absolute;
        left: 24px;
      }
      &:first-of-type {
        padding: 20px 0 20px 72px;
      }
      &:last-of-type {
        border-bottom: 1px dotted #0C3976;
        &:after {
          content: none;
        }
      }
    }
  }
  &--box {
    font-size: 14px;
    font-weight: bold;
    display: block;
    &:after {
      content: '';
      width: 0;
      height: 3px;
      background: #0C3976;
      display: inline-block;
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: translate(-50%, 0);
      transition: .15s;
    }
    &:hover {
      &:after {
        width: 80%;
      }
    }
    @include sp {
      font-size: 16px;
      &:hover {
        &:after {
          width: 0;
        }
      }
    }
  }
  &--link_sp {
    @include sp {
      display: block;
      margin: 0 auto 32px;
      width: 245px;
    }
  }
  &--img {
    display: none;
    @include sp {
      display: block;
    }
  }
  &--strong {
    display: none;
    @include sp {
      font-size: 16px;
      display: block;
      text-align: center;
      margin: 0 0 40px;
      position: relative;
      &:after {
        content: '';
        width: 40px;
        height: 5px;
        display: inline-block;
        background: linear-gradient(to left, #0061C3, #0C3976);
        position: absolute;
        bottom: -14px;
        left: calc(50% - 20px);
      }
    }
  }
  &--facebook {
    display: none;
    @include sp {
      font-weight: bold;
      color: #fff;
      display: block;
      width: calc(100% - 40px);
      margin: 0 auto;
      background: #1877F2;
      padding: 13px 10px 13px 40px;
      border-radius: 8px;
      position: relative;
      opacity: 0;
      visibility: hidden;
      transition: opacity 1s, visibility 1s;
      &:before {
        content: '';
        width: 24px;
        height: 24px;
        display: inline-block;
        background: url("/common/images/gl-header_nav--facebook.svg") no-repeat;
        background-size: contain;
        position: absolute;
        left: 8px;
        top: calc(50% - 12px);
      }
    }
  }
}

.gl-header_menu {
  display: none;
  @include sp {
    display: block;
    background: #0C3976;
    width: 56px;
    height: 56px;
    position: fixed;
    top: 0;
    right: 0;
    &--bar {
      position: absolute;
      right: calc(50% - 10px);
      width: 20px;
      height: 3px;
      background-color: #fff;
      display: inline-block;
      transition: all .3s 0s ease;
      &:nth-of-type(1) {
        top: 16px;
      }
      &:nth-of-type(2) {
        top: 23px;
      }
      &:nth-of-type(3) {
        top: 30px;
      }
    }
    &--words {
      font-size: 10px;
      font-weight: bold;
      color: #fff;
      position: absolute;
      bottom: 8px;
      left: calc(50% - 15px);
    }
    &--close {
      display: none;
      font-size: 10px;
      font-weight: bold;
      color: #0C3976;
      position: absolute;
      bottom: 8px;
      left: calc(50% - 15px);
    }
    &.js-gl-header_menu-active {
      background: #F0F0F0;
      .gl-header_menu--bar {
        background: #0C3976;
        &:nth-of-type(1) {
          transform: rotate(45deg);
          top: 23px;
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:nth-of-type(3) {
          transform: rotate(-45deg);
          top: 23px;
        }
      }
      .gl-header_menu--words {
        display: none;
      }
      .gl-header_menu--close {
        display: block;
      }
    }
  }
}
